import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/_shared/_shared.module';
import { TarifasData } from 'src/app/footer/_models/tarifas-data';



@Component({
  selector: 'app-tarifas-creative',
  templateUrl: './tarifas-creative.component.html',
  styleUrls: ['./tarifas-creative.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    RouterModule
  ]
})
export class TarifasCreativeComponent
{

  constructor(
    public dialogRef: MatDialogRef<TarifasCreativeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TarifasData
  ) { }

}
